import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faPinterest,
  faTiktok,
  faWhatsapp,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="layout">
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="32"
              height="32"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M1 4h22v2H1V4zm0 14h22v2H1v-2zm18.622-3.086l-.174-.87h-1.949l-.31.863-1.562.003c1.005-2.406 1.75-4.19 2.236-5.348.127-.303.353-.457.685-.455.254.002.669.002 1.245 0L21 14.912l-1.378.003zm-1.684-2.062h1.256l-.47-2.18-.786 2.18zM7.872 9.106l1.57.002-2.427 5.806-1.59-.001c-.537-2.07-.932-3.606-1.184-4.605-.077-.307-.23-.521-.526-.622-.263-.09-.701-.23-1.315-.419v-.16h2.509c.434 0 .687.21.769.64l.62 3.289 1.574-3.93zm3.727.002l-1.24 5.805-1.495-.002 1.24-5.805 1.495.002zM14.631 9c.446 0 1.01.138 1.334.267l-.262 1.204c-.293-.118-.775-.277-1.18-.27-.59.009-.954.256-.954.493 0 .384.632.578 1.284.999.743.48.84.91.831 1.378-.01.971-.831 1.929-2.564 1.929-.791-.012-1.076-.078-1.72-.306l.272-1.256c.656.274.935.361 1.495.361.515 0 .956-.207.96-.568.002-.257-.155-.384-.732-.702-.577-.317-1.385-.756-1.375-1.64C12.033 9.759 13.107 9 14.63 9z" />
            </svg>
          </>
        
          </div>
          <div className="layout">
            {/* Menu Section */}
            <div className="layout_item w-25">
              <nav className="c-nav-tool">
                <h4 className="c-nav-tool_title">Menu</h4>
                <ul className="c-nav-tool_list">
                  <li>
                    <Link to="/cart" className="c-link">
                      Cart
                    </Link>
                  </li>
                  <li>
                    {/*<Link to="/contact" className="c-link">
                      contact
                    </Link>
                     
                  <li>
                    <Link to="/Terms" className="c-link">
                      Terms of Service
                    </Link>
                  </li>
                  */}
                  </li>
                  <li>
                    <Link to="/login" className="c-link">
                      Login
                    </Link>
                  </li>
                  {/*<li>
                    <Link to="/medium-posts" className="c-link">
                      blog
                    </Link>
                  </li>*/}
                 
                </ul>
              </nav>
            </div>
          </div>
          
          <div className="layout">
          <>
              <a
                href="https://web.facebook.com/profile.php?id=100094722294824"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
              <a
                href="https://www.youtube.com/channel/UC0wIPV0HANk5bEXzoZsmFDg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
              <a
                href="https://www.instagram.com/craig.kanyemba/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a
                href="https://wa.me/+27639236774"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
              </a>
            </>
       
        <div className="layout">
          <div className="layout_item w-50"></div>
        </div>
      </div>

        <div className="footer_copyright">
          <p>&copy; 2024 Craig Kanyemba.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
