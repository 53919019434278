import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};
const ForPost = () => {
  const { items: data, status } = useSelector((state) => state.postCollections);

  const extractFirstTwoParagraphs = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;
    const paragraphs = tempDiv.querySelectorAll("p");
    return Array.from(paragraphs)
      .slice(0, 2)
      .map((p) => p.outerHTML)
      .join("");
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: (i) => <div>{i + 1}</div>
  };

  return (
    <div className="article-container">
      {status === "success" ? (
        <>
          <h2>Blog Posts</h2>
          <div className="slider-container">
            <Slider {...settings}>
              {data &&
                data.map((postCollection) => (
                  <div key={postCollection._id} className="article-overlay">
                    <h3>{postCollection.title}</h3>
                    <img
                      src={postCollection.image.url}
                      alt={postCollection.image}
                      className="articles-image"
                    />
                    <div className="article-details">
                      <div
                        className="article-content"
                        dangerouslySetInnerHTML={{
                          __html: extractFirstTwoParagraphs(
                            postCollection.content
                          )
                        }}
                      />
                      <Link to={`/postCollection/${postCollection._id}`}>
                        read more ...
                      </Link>
                      <div>
                        <span>
                          {moment(postCollection.date).format("MMMM Do YYYY")}
                        </span>
                      </div>
                      <div>
                        <span>{postCollection.author}</span>
                      </div>
                      <button>Share</button>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </>
      ) : status === "pending" ? (
        <p>Loading...</p>
      ) : (
        <p>Unexpected error occurred...</p>
      )}
      {/* Additional Sections */}
      <div className="layout c-2">
        <div className="layout_item w-50">
          <div className="newsletter">
            <h3 className="newsletter_title">
              Get updates on exciting news and insights delivered straight to
              your inbox.
            </h3>
            <form className="form" action="">
              <input type="text" placeholder="enter email ..." />
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" />
                </svg>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForPost;
