import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url, setHeaders } from "./api";
import { toast } from "react-toastify";

const initialState = {
  items: [],
  status: null,
  createStatus: null,
  deleteStatus: null,
  editStatus: null,
};

export const postCollectionsFetch = createAsyncThunk(
    "postCollections/postCollectionsFetch",
    async () => {
      try {
        const response = await axios.get(`${url}/postCollections`);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    }
  );

  export const postCollectionsCreate = createAsyncThunk(
    "postCollections/postCollectionsCreate",
    async (values) => {
      try {
        const response = await axios.post(
          `${url}/postCollections`,
          values,
          setHeaders()
        );
  
        return response.data;
      } catch (error) {
        console.log(error);
        toast.error(error.response?.data);
      }
    }
  );

  export const postCollectionsEdit = createAsyncThunk(
    "postCollections/postCollectionsEdit",
    async ({ values }) => {
      try {
        const response = await axios.put(
          `${url}/postCollections/${values.postCollections._id}`,
          values,
          setHeaders()
        );
        return response.data;
      } catch (error) {
        console.log(error);
        toast.error(error.response?.data);
      }
    }
  );

  export const postCollectionsDelete = createAsyncThunk(
    "postCollections/postCollectionsDelete",
    async (id) => {
      try {
        const response = await axios.delete(
          `${url}/postCollections/${id}`, 
          setHeaders()
        );
  
        return response.data;
      } catch (error) {
        console.log(error);
        toast.error(error.response?.data);
      }
    }
  );
  

  const postCollectionsSlice = createSlice({
    name: "postCollections",
    initialState,
    reducers: {},
    extraReducers: {
      [postCollectionsFetch.pending]: (state, action) => {
        state.status = "pending";
      },
      [postCollectionsFetch.fulfilled]: (state, action) => {
        state.items = action.payload;
        state.status = "success";
      },
      [postCollectionsFetch.rejected]: (state, action) => {
        state.status = "rejected";
      },
      [postCollectionsCreate.pending]: (state, action) => {
        state.createStatus = "pending";
      },
      [postCollectionsCreate.fulfilled]: (state, action) => {
        state.items.push(action.payload);
        state.createStatus = "success";
        toast.success("postCollections Collection Created");
      },
      [postCollectionsCreate.rejected]: (state, action) => {
        state.createStatus = "rejected";
      },
      [postCollectionsEdit.pending]: (state, action) => {
        state.editStatus = "pending";
      },
      [postCollectionsEdit.fulfilled]: (state, action) => { 
        const updatedPostCollections = state.items.map((post) =>
        post._id === action.payload._id ? action.payload : post
        );  
        state.items = updatedPostCollections;
        state.editStatus = "success";
        toast.info("postCollections Collection Edited");
      },
      [postCollectionsEdit.rejected]: (state, action) => {
        state.editStatus = "rejected";
      },
      [postCollectionsDelete.pending]: (state, action) => {
        state.deleteStatus = "pending";
      },
      [postCollectionsDelete.fulfilled]: (state, action) => {
        const newList = state.items.filter(
          (item) => item._id !== action.payload._id
        );
        state.items = newList;
        state.deleteStatus = "success";
        toast.error("Post Collection Deleted"); // Fix typo here
      },      
      [postCollectionsDelete.rejected]: (state, action) => {
        state.deleteStatus = "rejected";
      },
    },
  });

export default postCollectionsSlice.reducer;
