import React from "react";

const Returns = () => {
  

  return (
    <div className="returns-refunds">
      
        <h1 className="about-heading">Returns and Refunds</h1>
       

        <h2 className="about-heading"> Returns Policy Overview:</h2>
        <p className="about-text">
          We want you to be completely satisfied with your purchase. If for any
          reason you are not satisfied, we will gladly accept returns within
          [7] days of delivery.
        </p>

        <h2 className="about-heading"> Eligibility for Returns: </h2>
        <p className="about-text">
          Items must be unused, in their original packaging, and in the same
          condition as received. 
        </p>

        <h2 className="about-heading"> Initiating a Return:</h2>
        <p className="about-text">
          To initiate a return, please contact our customer service team within
          [30] days of delivery to obtain a Return Merchandise Authorization
          (RMA) number. You can reach us via email in contact
        </p>

        <h2 className="about-heading"> Return Shipping:</h2>
        <p className="about-text">
          Customers are responsible for return shipping costs unless the item
          received was damaged, defective, or incorrect. We recommend using a
          trackable shipping service and purchasing shipping insurance for
          returns.
        </p>

        <h2 className="about-heading"> Refunds: </h2>
        <p className="about-text">
          Refunds will be issued to the original payment method within [30]
          business days after we receive and inspect the returned item. Original
          shipping charges are non-refundable unless the return is due to our
          error.
        </p>

        <h2 className="about-heading"> Exchanges: </h2>
        <p className="about-text">
          If you would like to exchange an item for a different size, color, or
          style, please contact our customer service team to arrange for an
          exchange.
        </p>
        <h2 className="about-heading"> Damaged or Defective Items: </h2>
        <p className="about-text">
          If you receive a damaged, defective, or incorrect item, please contact
          us immediately to arrange for a replacement or refund. Exceptions and
          Special Circumstances: In certain situations, exceptions to our
          standard returns policy may be made at our discretion. We reserve the
          right to refuse returns that do not meet our criteria or to charge a
          restocking fee for returned items.
        </p>
        <h2 className="about-heading"> Policy Updates: </h2>
        <p className="about-text">
          We reserve the right to update or modify our returns policy at any
          time without prior notice. Any changes will be effective immediately
          upon posting on our website.
        </p>
        <h2 className="about-heading"> Contact Information: </h2>
        <p className="about-text">
          If you have any questions or concerns about our returns and refunds
          policy, please don't hesitate to contact us.
        </p>
      </div>
   
  );
};

export default Returns;
