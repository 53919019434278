import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../features/cartSlice";
import { Link } from "react-router-dom";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ForPost from "../components/ForPost";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};
const Home = () => {
  const { items: data, status } = useSelector((state) => state.products);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: (i) => <div>{i + 1}</div>
  };

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
    navigate("/cart");
  };

  // Inline CSS for the background image
  return (
    <div className="home-container">
      <div className="hero-image">
        <img src="./images/unnamed.jpg" alt="Hero Image" />
        <div className="hero-text">
          <h2>Craig Kanyemba</h2>
          <div>
            <div>
              <span>
                <p>Author, Software Engineer, Entrepreneur</p>
              </span>
            </div>
          </div>
          <h2>MISSION AND VALUES</h2>
          <div>
            <div>
              <span>
                <p>
                 He has published six books for the past 6 years ranging from contemporary novels to none-fictional books. His first book was published in 2018, Shepherd and the Lost Sheep: A Motherless Child, a contemporary Novel published in South Africa. Craig's writings are learned towards Pan-Africanism, and Africa's creativity, social justice and shaping African stories and minds.
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
      {status === "success" ? (
        <>
          <div className="slider-container">
            <Slider {...settings}>
              {data &&
                data?.map((product) => (
                  <div key={product._id} className="product-overlay">
                    <Link to={`/product/${product._id}`}>
                      <img
                        src={product.image.url}
                        alt={product.name}
                        className="slick-slide"
                      />
                    </Link>
                    <div className="product-details">
                      <h3>{product.name}</h3>
                      <div className="details">
                        <span>{product.desc}</span>
                        <div>
                          <a
                            href={product.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            find out on ebooks
                          </a>
                        </div>
                        <div>
                          <span>
                            {moment(product.date).format("MMMM Do YYYY")}
                          </span>
                        </div>
                        <span className="price">R{product.price}</span>
                      </div>
                      <div>
                        <button
                          className="product-button"
                          onClick={() => handleAddToCart(product)}
                        >
                          {" "}
                          paperback{" "}
                        </button>
                      </div>
                      
                      
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </>
      ) : status === "pending" ? (
        <p> Loading ... </p>
      ) : (
        <p>Unexpected error occured ... </p>
      )}
    </div>
  );
};

export default Home;
