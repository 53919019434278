import { useState, useEffect } from "react";
import { setHeaders, url } from "../../features/api";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CommentSection from "./CommentSection";

const PostCollection = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [showComments, setShowComments] = useState(false);

  const [postCollection, setPostCollection] = useState({});
  const [loading, setLoading] = useState(false);

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      try {
        const res = await axios.get(
          `${url}/postCollections/find/${params.id}`,
          setHeaders()
        );
        setPostCollection(res.data);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    }

    fetchData();
  }, [params.id]);

  return (
    <div className="post-container">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <div className="post-header">
            <h3>{postCollection.title}</h3>
            <p className="post-date">
              {moment(postCollection.date).format("MMMM Do YYYY")}
            </p>
          </div>
          <div>
            <img
              src={postCollection.image?.url}
              alt="post"
              className="post-image"
            />
          </div>
          <div className="post-details">
            <p>
              <span>Author:</span> {postCollection.author}
            </p>
            <div
              className="post-content"
              dangerouslySetInnerHTML={{ __html: postCollection.content }}
            />
          </div>
          <button className="like-button">Like</button>
          <button className="comment-button" onClick={toggleComments}>Comment</button>
        {showComments && <CommentSection />}
        </div>
      )}
    </div>
  );
};

export default PostCollection;
