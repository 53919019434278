import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import CheckoutSuccess from "./components/CheckoutSuccess";

import Cart from "./components/Cart";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import ForPost from "./components/ForPost";
import ForEvent from "./components/ForEvent";
import About from "./components/About";
import Contact from "./components/Contact";
import Dashboard from "./components/admin/Dashboard";
import shipping from "./components/shipping";
import Terms from "./components/Terms";
import Returns from "./components/Returns";
import Orders from "./components/admin/Orders";
import UserProfile from "./components/Details/UserProfile";
import Order from "./components/Details/Order";
import Product from "./components/Details/Product";
import PostCollection from "./components/Details/PostCollection";
import Socials from "./components/admin/Socials";
import Calendar from "./components/admin/Calendar";
import Products from "./components/admin/Products";
import NewEvents from "./components/admin/NewEvents";
import NewPostCollections from "./components/admin/NewPostCollections.jsx";
import Summary from "./components/admin/Summary";
import CreateProduct from "./components/admin/CreateProduct";
import CreateEvent from "./components/admin/CreateEvent";
import CreatePostCollection from "./components/admin/CreatePostCollection.jsx";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadUser } from "./features/authSlice";
import ProductsList from "./components/admin/list/ProductsList";
import EventsList from "./components/admin/list/EventsList";
import Users from "./components/admin/Users";
import PostCollectionsList from "./components/admin/list/PostCollectionsList.jsx";


function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser(null));
  }, [dispatch]);

  const bgStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/background.jpg'})`,
    backgroundSize: 'cover', // Cover the entire container
    backgroundPosition: 'center', // Center the background image
    height: '100%', // Full viewport height
    width: '100%', // Full viewport width
  };

  return (
    <div styles={bgStyle} className="App">
      <Router>
        <ToastContainer />
        <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/checkout-success" element={<CheckoutSuccess />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/medium-posts" element={<ForPost />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/shipping" element={<shipping />} />
            <Route path="/Returns" element={<Returns />} />
            <Route path="Terms" element={<Returns />} />
            <Route path="/forevent" element={<ForEvent />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/postCollection/:id" element={<PostCollection />} />
            <Route path="/order/:id" element={<Order />} />
            <Route path="/user/:id" element={<UserProfile />} />
            <Route path="/admin" element={<Dashboard />}>
              <Route path="products" element={<Products />}>
                <Route index element={<ProductsList />} />
                <Route path="create-product" element={<CreateProduct />} />
              </Route>

              <Route path="newpostCollections" element={<NewPostCollections />}>
                <Route index element={<PostCollectionsList />} />
                <Route path="create-postCollection" element={<CreatePostCollection />} />
              </Route>

              <Route path="events" element={<NewEvents />}>
                <Route index element={<EventsList />} />
                <Route path="create-event" element={<CreateEvent />} />
              </Route>
              
              <Route path="summary" element={<Summary />} />
              <Route path="users" element={<Users />} />
              <Route path="orders" element={<Orders />} />
              <Route path="socials" element={<Socials />} />
              <Route path="calendar" element={<Calendar />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
      </Router>
    </div>
  );
}

export default App;
